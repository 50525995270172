@import url('_colors.css');

#lista-dashboard {
    padding: 3rem;
}

#lista-dashboard h1,
#lista-dashboard h2 {
    font-size: 2.3rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
}

#lista-dashboard h2 {
    font-size: 1.6rem;
}

#lista-dashboard h2 .blue{
    color: var(--dodger-blue);
}

#lista-dashboard .bulb {
    background: url('../img/lista-hero-bulb.png') no-repeat;
    background-size: cover;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.7rem;
}

#lista-dashboard .list-icon {
    width: 3rem;
    height: 3rem;
    margin-right: 0.7rem;
}

#lista-dashboard .action-info-icon {
    max-width: 70px;
    min-width: 60px;
    margin-right: 1rem;
}

#lista-dashboard .procedure-list-icon {
    background: url('../img/procedure-list-icon.png') no-repeat;
    background-size: contain;
}

#lista-dashboard .used-procedure-list-icon {
    background: url('../img/used-procedure-list-icon.png') no-repeat;
    background-size: contain;
}

#lista-dashboard .invite-icon {
    background: url('../img/invite.png') no-repeat;
    background-size: contain;
}

#lista-dashboard .tag-icon {
    background: url('../img/tag.png') no-repeat;
    background-size: contain;
}

#lista-dashboard .guide-icon {
    background: url('../img/guide.png') no-repeat;
    background-size: contain;
}

#lista-dashboard .welcome-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

#lista-dashboard .welcome-description {
    margin-left: -15px;
    padding-bottom: 1rem;
    line-height: 1.5;
    font-size: 1.125rem;
    color: var(--lista-dark-gray);
    padding-top: 1rem;
}

#lista-dashboard .welcome-description-row {
    margin-left: 0;
    margin-right: 0;
}

#lista-dashboard .intro-video {
    max-width: 100%;
}

.intro-video-modal .video-container {
    margin: 0 auto;
    text-align: center;
}

.intro-video .video-container,
#lista-dashboard .intro-video .video-container {
    max-width: 100%;
    min-width: 560px;
    min-height: 315px;
}

.intro-video .video-container iframe,
#lista-dashboard .intro-video .video-container iframe {
    max-width: 100%;
}

@media(min-width: 1074px) {
    #lista-dashboard .intro-video {
        padding-bottom: 225px;
        position: relative;
    }

    #lista-dashboard .intro-video .video-container {
        position: absolute;
        top: -65px;
        right: 0;
    }
}

#lista-dashboard .main-section {
    background-color: #f5f7fa;
    border: 1px solid #dce1e6;
    border-radius: 5px;
}

#lista-dashboard .template-wrapper {
    padding: 1.5rem 0.8rem;
    min-height: 335px;
    margin-top: 1.5rem;
}

#lista-dashboard .col-custom {
    padding: 3rem;
}

#lista-dashboard .col-item {
    display: flex;
    justify-content: center;
}

#lista-dashboard .action-help {
    font-size: 0.7rem;
    color: #737374;
    margin-top: 2rem;
    text-align: center;
    font-style: italic;
    font-weight: 500;
}

#lista-dashboard .action-help-content {
    max-width: 280px;
    margin: 0 auto;
}

#lista-dashboard .btn.my-first-procedure,
#lista-dashboard .btn.see-an-example{
    background-color: var(--dodger-blue);
    border-color: var(--dodger-blue);
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    width: 300px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
}

#lista-dashboard .btn.action-info-btn {
    background-color: var(--dodger-blue);
    border-color: var(--dodger-blue);
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.7rem 2rem;
    margin-left: 15px;
}

#lista-dashboard .btn.see-an-example,
#lista-dashboard .btn.view-all-btn  {
    background-color: #fff;
    border-color: var(--dodger-blue);
    color: var(--dodger-blue);
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.8rem 3rem;
}

#lista-dashboard .btn.view-all-btn  {
    padding: 0.7rem 2rem;
}

#lista-dashboard .btn.create-btn  {
    padding: 0.7rem 2rem;
    font-size: 0.8rem;
    font-weight: 600;
    height: unset;
}

#lista-dashboard .btn.see-an-example:hover{
    background-color: var(--dodger-blue);
    border-color: var(--dodger-blue);
    color: #fff;
}

#lista-dashboard .dropdown-toggle:after {
    content: initial;
}

#lista-dashboard .table-responsive {
    display: table;
    overflow-x: visible;
}

#lista-dashboard .dropdown-toggle {
    background-color: transparent!important;
    border-color: transparent;
    /*color: inherit;*/
    color: var(--dodger-blue);
    padding: 0 0.25rem;
}

#lista-dashboard .dropdown-menu {
    transform: translate3d(-96px, 27px, 0px)!important;
}

#lista-dashboard .col-header {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

#lista-dashboard .col-description {
    margin-left: 0;
}

#lista-dashboard .arrow-right-wrapper {
    font-size: xx-large;
    text-align: center;
    padding-top: 1rem;
    line-height: 1.7;
}

#lista-dashboard .button-wrapper {
    display: flex;
    justify-content: space-between;
}

#lista-dashboard .procedure-list-table-wrapper,
#lista-dashboard .usedprocedure-list-table-wrapper {
    padding-bottom: 0;
    min-height: 177px;
}

#lista-dashboard .action-info {
    font-size: 1rem;
    color: var(--lista-dark-gray);
    font-weight: 500;
    line-height: 1.5;
}

#lista-dashboard .action-info.min-height {
    min-height: 80px;
}

#lista-dashboard .template-welcome-wrapper {
    padding: 2rem 2rem 0;
    min-height: 300px;
    border-top: none;
    border-radius: 0 0 5px 5px;
}

#lista-dashboard .action-info-content.max-width {
    max-width: 280px;
}

#lista-dashboard .custom-toggle {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    margin-top: 1rem;
    background-color: #f4f7fa;
    border-left: 1px solid #dce1e6;
    border-top: 1px solid #dce1e6;
    border-right: 1px solid #dce1e6;
    margin-bottom: -2px;
    padding-right: 1rem;
    height: 30px;
}

#lista-dashboard .custom-toggle-btn {
    border: none;
    background-color: #e7eaed;
    border-width: 1px;
    font-size: 0.65rem;
    font-weight: 600;
    width: 70px;
    margin-right: 1rem;
    margin-bottom: 5px;
    border-radius: 0 0 3px 3px;
}

#lista-dashboard .action-info-col {
    margin: 2rem auto;
}

#lista-dashboard .fa-xs {
    font-size: 0.5rem;
    padding-left: 0.2rem;
}

#lista-dashboard .resources-wrapper {
    min-height: 200px;
    padding: 1.5rem 0.8rem;
    margin-top: 1.5rem;
}

#lista-dashboard .resource-icon {
    padding-left: 0.7rem;
}

#lista-dashboard .btn-resource {
    text-decoration: underline;
}

@media( max-width: 576px) {
    #lista-dashboard {
        padding: 0.5rem;
    }
}

#lista-dashboard .skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
    width: 100%;
    border-radius: .125rem;
}

#lista-dashboard .skeleton-text {
    height: .7rem;
    margin-bottom: .4rem;
}

#lista-dashboard .skeleton-h1 {
    height: 2.3rem;
}

#lista-dashboard .skeleton-text:last-child {
    margin-bottom: 0;
    width: 80%;
}

@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
}

.resources-wrapper.blog-card {
    display: flex;
    justify-content: space-between;
}

.blog-card .left-one-third {
    width: 31.5%;
}

.blog-card .right-two-thirds {
    width: 65.5%;
}

.lista-responsive-image {
    width: 100%;
    height: auto;
    border-radius: 6px;
}

#lista-dashboard .resources-wrapper .col-header {
    padding-bottom: 0;
}

#lista-dashboard .resources-wrapper .col-description {
    padding-top: 0;
}

