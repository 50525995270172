.uppy-DashboardItem-sourceIcon {
    display: inline-block;
    float: right;
}
.uppy-DashboardTabs-list {
    display: none;
}

.uppy-download-button-icon {
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    position: absolute;
    top: -10px;
    right: 10px;
}
.uppy-download-button-icon:not(.uppy-download-button-icon-js) {
    opacity: 0.5;
}

.uppy-remove-button-icon {
    color: #b33d31;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    position: absolute;
    top: -10px;
    right: -10px;
}
.uppy-remove-button-icon:not(.uppy-remove-button-icon-js) {
    opacity: 0.5;
}
