.tree > div[data-react-beautiful-dnd-droppable] {
    padding-bottom: 35px !important; /* make space for last row to drag & drop */
}

.tree > div > div .used-procedure-info-wrapper,
.tree > div > div .extends-tree-item,
.tree > div > div .delete-tree-item {
    visibility: hidden;
}

.rootChildren .tree  .isEditable ~ .delete-tree-item {
    visibility: visible;
}

.rootChildren .tree  .isEditable ~ .edit-tree-item {
    visibility: visible;
}

.rootChildren .tree  .isEditable {
    position: relative;
    z-index: 99;
}

.rootChildren .tree  .isEditable ~ .edit-tree-item .fa-edit:before {
    content: "\f00c";
    font-size: 18px;
}

.tree > div > div .edit-tree-item {
    visibility: hidden;
}
.tree > div > div:hover .edit-tree-item {
    visibility: visible
}

.tree .item .select-tree-item {
    display: none;
}
.tree > div > div:hover .select-tree-item,
.tree .item.selected-item-parent .select-tree-item {
    display: inline-block;
}

.procedureSteps .extends-tree-item .procedure-description-wrapper,
.procedureSteps .extends-tree-item .procedure-attachment-wrapper,
.usedProcedureSteps .used-procedure-info-wrapper .used-procedure-info-toggle-note {
    visibility: hidden;
}

.usedProcedureSteps .used-procedure-info-wrapper.isOpen,
.usedProcedureSteps .used-procedure-info-wrapper.with-note,
.usedProcedureSteps .used-procedure-info-wrapper.with-description,
.usedProcedureSteps .used-procedure-info-wrapper.with-files,
.usedProcedureSteps .tree-item-field:hover .used-procedure-info-wrapper,
/*.usedProcedureSteps .isFocused ~ .used-procedure-info-wrapper,*/
.usedProcedureSteps .used-procedure-info-wrapper .used-procedure-info-toggle-note.with-note,
.usedProcedureSteps .used-procedure-info-wrapper.isOpen .used-procedure-info-toggle-note,
.usedProcedureSteps .tree-item-field:hover .used-procedure-info-wrapper .used-procedure-info-toggle-note,
.procedureSteps .extends-tree-item.isOpen,
.procedureSteps .extends-tree-item.with-description,
.procedureSteps .extends-tree-item.with-files,
.procedureSteps .extends-tree-item.with-description .procedure-description-wrapper,
.procedureSteps .extends-tree-item .procedure-description-wrapper.with-description,
.procedureSteps .extends-tree-item .procedure-attachment-wrapper.with-files,
.procedureSteps .extends-tree-item.isOpen .procedure-description-wrapper,
.procedureSteps .extends-tree-item.isOpen .procedure-attachment-wrapper,
.procedureSteps .isFocused ~ .extends-tree-item-wrapper .extends-tree-item .procedure-description-wrapper,
.procedureSteps .isFocused ~ .extends-tree-item-wrapper .extends-tree-item .procedure-attachment-wrapper,
.procedureSteps .isFocused ~ .extends-tree-item-wrapper .extends-tree-item,
.procedureSteps .isFocused ~ .extends-tree-item .procedure-description-wrapper,
.procedureSteps .isFocused ~ .extends-tree-item .procedure-attachment-wrapper,
.procedureSteps .isFocused ~ .extends-tree-item,
.procedureSteps .isFocused ~ .delete-tree-item {
    visibility: visible!important;
}

@media (hover: none) {
    .usedProcedureSteps .isFocused ~ .used-procedure-info-wrapper {
        visibility: visible !important;
    }
}
