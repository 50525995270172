.manage-subscription {
    background-color: transparent !important;
    border: 0 !important;
}

.manage-subscription .subscription-seat-counts .subscription-seat-count-total,
.manage-subscription .subscription-seat-counts .subscription-seat-count-used,
.manage-subscription .subscription-seat-counts .subscription-seat-count-available {
    border-width: 1px;
    border-style: solid;
}

.manage-subscription .update-subscription-quantity-group {
    border-width: 1px;
    border-style: solid;
}
.manage-subscription .update-subscription-quantity-group .input-group-prepend button,
.manage-subscription .update-subscription-quantity-group .input-group-append button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.manage-subscription .update-subscription-quantity-group #update-subscription-quantity-number {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    width: 25px;
}

.manage-subscription .payment-profile {
    border-width: 1px;
    border-style: solid;
}
.manage-subscription .payment-profile .payment-info {
    font-weight: bold;
    text-transform: capitalize;
}
