@import url('_colors.css');

#first-experience footer {
    color: var(--darker-dodger-blue);
    background-color: white;
}

#first-experience .callout {
    padding: 20px;
    margin: 0 0 20px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-left-width: 5px;
    border-radius: 3px;
    border-left-color: var(--dodger-blue);
    background-color: rgba(0, 0, 0, 0.03);
}

#first-experience .container {
    margin-top: 4.5em;
    margin-bottom: 4.5em;
}

#first-experience h1 {
    font-size: 3em;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1.25em;
}

#first-experience .blue-text {
    color: #1776cc;
}

#first-experience h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0 0 .75em;
}

#first-experience .callout-welcome {
    border: 0;
    border-radius: .625em;
    background-color: #f0eadb;
    padding: 30px;
    margin-bottom: 2.5em;
}

#first-experience .callout-welcome h2 {
    color: #f22b60;
    display: block;
}

#first-experience p {
    font-size: 1.25em;
    line-height: 1.6;
}

#first-experience .callout-welcome p {
    font-weight: bold;
}

#first-experience .heading-icon,
#first-experience .col-md-6 h2 {
    display: inline-block;
    vertical-align: middle;
}

#first-experience .heading-icon {
    width: 16.67%;
    max-width: 65px;
    margin-bottom: 1.25em;
}

#first-experience .col-md-6 h2 {
    margin: 0 0 .833em .625em;
}

#first-experience p:last-of-type {
    margin-bottom: 0;
}

#first-experience .lista-features {
    margin-bottom: 2.5em;
}

#first-experience .btn-primary {
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    #first-experience .container {
        text-align: center;
    }
    #first-experience .lista-features div:not(:last-child) {
        margin-bottom: 2.5em;
    }
}
