.tree-wrapper.usedProcedureSteps .tree .tree-item-field {
    /*background-color: #f7f8fb;*/
    z-index: 1;
    margin-left: 4px;
}
.tree-wrapper.usedProcedureSteps .tree .indent.parent-open,
.tree-wrapper.usedProcedureSteps .tree .indent.parent-closed {
    z-index: 2;
}

.medium-zoom-image--opened {
    z-index: 1;
}

.tree-wrapper.usedProcedureSteps .tree .spacer {
    height: 100%;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-1 {
    margin-top: -22px;
    min-height: 70px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-2 {
    margin-top: -42px;
    min-height: 90px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-3 {
    margin-top: -62px;
    min-height: 110px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-4 {
    margin-top: -82px;
    min-height: 130px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-5 {
    margin-top: -102px;
    min-height: 150px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-6 {
    margin-top: -122px;
    min-height: 170px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-7 {
    margin-top: -142px;
    min-height: 190px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-8 {
    margin-top: -162px;
    min-height: 210px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-9 {
    margin-top: -182px;
    min-height: 230px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-10 {
    margin-top: -202px;
    min-height: 250px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-11 {
    margin-top: -222px;
    min-height: 270px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-12 {
    margin-top: -242px;
    min-height: 290px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-13 {
    margin-top: -262px;
    min-height: 310px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-14 {
    margin-top: -282px;
    min-height: 330px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-15 {
    margin-top: -302px;
    min-height: 350px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-16 {
    margin-top: -322px;
    min-height: 370px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-17 {
    margin-top: -342px;
    min-height: 390px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-18 {
    margin-top: -362px;
    min-height: 410px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-19 {
    margin-top: -382px;
    min-height: 430px;
}
.tree-wrapper.usedProcedureSteps .tree .spacer.spacer-20 {
    margin-top: -402px;
    min-height: 450px;
}

@media (max-width: 935px) {
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-1 {
        margin-top: -52px;
        min-height: 100px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-2 {
        margin-top: -72px;
        min-height: 120px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-3 {
        margin-top: -92px;
        min-height: 140px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-4 {
        margin-top: -112px;
        min-height: 160px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-5 {
        margin-top: -132px;
        min-height: 180px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-6 {
        margin-top: -152px;
        min-height: 200px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-7 {
        margin-top: -172px;
        min-height: 220px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-8 {
        margin-top: -192px;
        min-height: 240px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-9 {
        margin-top: -212px;
        min-height: 260px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-10 {
        margin-top: -232px;
        min-height: 280px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-11 {
        margin-top: -252px;
        min-height: 300px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-12 {
        margin-top: -272px;
        min-height: 320px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-13 {
        margin-top: -292px;
        min-height: 340px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-14 {
        margin-top: -322px;
        min-height: 360px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-15 {
        margin-top: -332px;
        min-height: 380px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-16 {
        margin-top: -352px;
        min-height: 400px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-17 {
        margin-top: -372px;
        min-height: 420px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-18 {
        margin-top: -392px;
        min-height: 440px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-19 {
        margin-top: -422px;
        min-height: 460px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-20 {
        margin-top: -432px;
        min-height: 480px;
    }
}

@media (max-width: 660px) {
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-1 {
        margin-top: -72px;
        min-height: 120px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-2 {
        margin-top: -112px;
        min-height: 160px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-3 {
        margin-top: -122px;
        min-height: 170px;
    }
}

@media (max-width: 576px) {
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-1 {
        margin-top: -92px;
        min-height: 140px;
    }
}

@media (max-width: 500px) {
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-4 {
        margin-top: -132px;
        min-height: 180px;
    }
}

@media (max-width: 440px) {
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-1 {
        margin-top: -122px;
        min-height: 170px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-2 {
        margin-top: -142px;
        min-height: 190px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-3 {
        margin-top: -162px;
        min-height: 210px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-4 {
        margin-top: -182px;
        min-height: 230px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-5 {
        margin-top: -202px;
        min-height: 250px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-6 {
        margin-top: -222px;
        min-height: 270px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-7 {
        margin-top: -242px;
        min-height: 290px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-8 {
        margin-top: -262px;
        min-height: 310px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-9 {
        margin-top: -282px;
        min-height: 330px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-10 {
        margin-top: -302px;
        min-height: 350px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-11 {
        margin-top: -322px;
        min-height: 370px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-12 {
        margin-top: -342px;
        min-height: 390px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-13 {
        margin-top: -362px;
        min-height: 410px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-14 {
        margin-top: -382px;
        min-height: 430px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-15 {
        margin-top: -402px;
        min-height: 450px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-16 {
        margin-top: -422px;
        min-height: 470px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-17 {
        margin-top: -442px;
        min-height: 490px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-18 {
        margin-top: -462px;
        min-height: 510px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-19 {
        margin-top: -482px;
        min-height: 530px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-20 {
        margin-top: -502px;
        min-height: 550px;
    }
}

@media (max-width: 400px) {
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-1 {
        margin-top: -122px;
        min-height: 170px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-2 {
        margin-top: -242px;
        min-height: 290px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-3 {
        margin-top: -262px;
        min-height: 310px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-4 {
        margin-top: -282px;
        min-height: 330px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-5 {
        margin-top: -302px;
        min-height: 350px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-6 {
        margin-top: -322px;
        min-height: 370px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-7 {
        margin-top: -342px;
        min-height: 390px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-8 {
        margin-top: -362px;
        min-height: 410px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-9 {
        margin-top: -382px;
        min-height: 430px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-10 {
        margin-top: -402px;
        min-height: 450px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-11 {
        margin-top: -422px;
        min-height: 470px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-12 {
        margin-top: -442px;
        min-height: 490px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-13 {
        margin-top: -462px;
        min-height: 510px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-14 {
        margin-top: -482px;
        min-height: 530px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-15 {
        margin-top: -502px;
        min-height: 550px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-16 {
        margin-top: -522px;
        min-height: 570px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-17 {
        margin-top: -542px;
        min-height: 590px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-18 {
        margin-top: -562px;
        min-height: 610px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-19 {
        margin-top: -582px;
        min-height: 630px;
    }
    .tree-wrapper.usedProcedureSteps .tree .spacer.spacer-20 {
        margin-top: -602px;
        min-height: 650px;
    }
}
