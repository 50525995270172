.form-legend-container > .tree-wrapper.procedureSteps {
    flex-basis: 80%;
}

.form-legend-container .keyboard-shortcut-legend-container {
    background-color: #f7f8fb;
    border-radius: 5px;
    margin-left: 25px;
    padding: 15px;
    flex-basis: 20%;
}

.legend-fixed {
    position: fixed;
    right: 2%;
    top:0;
    width: 19%;
    height: 85vh;
    overflow: scroll;
}

.legend-fixed-collapsed {
    width: 40px!important;
    height: 60vh;
}

.tree-wrapper-width-fixed {
    flex-basis: 78.8%!important;
}

.tree-wrapper-width-toggle {
    flex-basis: 96.5%!important;
}

.form-legend-container .keyboard-shortcut-legend-container i {
    font-size: 25px;
    float: right;
    margin-left: -8px;
    margin-right: -8px;
}

.keyboard-shortcut-legend-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.keyboard-shortcut-legend-ul li h5 {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: bold;
}

.keyboard-shortcut-legend-ul li span {
    padding: 5px;
    float: right;
    margin-left: 20px;
    font-weight: normal;
}

.keyboard-shortcut-legend-ul li span:not(.icon-container) {
    border-radius: 4px;
    font-size: .6em;
    background-color: #f1f1f1;
    text-transform: uppercase;
    letter-spacing: .075em;
    font-weight: bold;
    border: 1px solid #bbb;
    box-shadow: 0px 1px #bbb;
}

.keyboard-shortcut-legend-ul li {
    font-weight: lighter;
    margin-bottom: 15px;
    line-height: 1.25;
}

.keyboard-shortcut-legend-ul li span i {
    background-color: none;
}

/* --------------  toggle legend ----------------*/
.form-legend-container .keyboard-shortcut-legend-container.toggle-legend-container {
    flex-basis: 2%;
    padding: 15px;
}

.toggle-legend-ul {
    display: none;
}

.form-legend-container > .tree-wrapper.procedureSteps.toggle-procedure-steps {
    flex-basis: 98%;
}

/* -------------- end of toggle----------------*/
@media (max-width: 1200px) {
    .tree-wrapper-width-toggle {
        flex-basis: 94.5%!important;
    }

    .tree-wrapper-width-fixed {
        flex-basis: 78%!important;
    }
}

@media (max-width: 992px) {
    .form-legend-container > .tree-wrapper.procedureSteps {
        flex-basis: 65%;
    }

    .form-legend-container .keyboard-shortcut-legend-container {
        padding: 20px;
        flex-basis: 35%;
    }

    .legend-fixed {
        width: 32.7%;
    }

    .tree-wrapper-width-fixed {
        flex-basis: 62.8%!important;
    }

    .tree-wrapper-width-toggle {
        flex-basis: 92.5%!important;
    }
}

@media (max-width: 768px){
    .tree-wrapper-width-toggle {
        flex-basis: 91%!important;
    }
}

@media (max-width: 500px) {
    .form-legend-container .keyboard-shortcut-legend-container {
        flex-basis: 2%;
        padding: 10px;
        margin-left: 5px;
    }

    .legend-fixed {
        width: 30px;
        right: 10px;
        height: 60vh;
    }

    .tree-wrapper-width-fixed {
        flex-basis: 93%!important;
    }

    .tree-wrapper-width-toggle {
        flex-basis: 38% !important;
    }

    .legend-fixed-collapsed {
        width: 58% !important;
        height: 80vh;
    }

    .keyboard-shortcut-legend-ul {
        display: none;
    }

    .form-legend-container > .tree-wrapper.procedureSteps {
        flex-basis: 98%;
    }
/* toggle legend mobile*/
    .form-legend-container .keyboard-shortcut-legend-container.toggle-legend-container {
        flex-basis: 60%;
        margin-left: 10px;
    }

    .toggle-legend-ul {
        display: block;
    }

    .form-legend-container > .tree-wrapper.procedureSteps.toggle-procedure-steps {
        flex-basis: 40%;
    }
    /* end toggle legend mobile*/
}
