.pdf-layout .PrivateLayout > nav,
.pdf-layout .update-procedure .form-actions-wrapper,
.pdf-layout .update-procedure .select-category-button-wrapper,
.pdf-layout .update-procedure .procedure-submit,
.pdf-layout .update-procedure .editing-using-procedure-label,
.pdf-layout .update-procedure .keyboard-shortcut-legend-container,
.pdf-layout .update-procedure .tree .procedure-step .extends-tree-item-wrapper,
.pdf-layout .update-procedure .tree .procedure-step .extends-tree-item,
.pdf-layout .update-procedure .tree .procedure-step .delete-tree-item,
.pdf-layout .update-procedure #procedure_description:placeholder-shown,
.pdf-layout .update-procedure #add-procedure-step,
.pdf-layout .update-used-procedure .form-actions-wrapper,
.pdf-layout .update-used-procedure form > button[type="submit"],
.pdf-layout .update-used-procedure .editing-using-procedure-label,
.pdf-layout .update-used-procedure .used-procedure-step-not-applicable .badge-light,
.pdf-layout .update-used-procedure .used-procedure-info-wrapper .used-procedure-info-note > button,
.pdf-layout .update-used-procedure .used-procedure-info-wrapper .used-procedure-info-note .quill .ql-toolbar,
.pdf-layout .PrivateLayout > footer {
    display: none;
}

.pdf-layout .update-procedure .tree-wrapper,
.pdf-layout .update-used-procedure .tree-wrapper,
body.pdf-layout {
    background-color: #ffffff;
}

.pdf-layout .update-procedure .tree .indent.dragger {
    color: #ffffff !important;
}

.pdf-layout .update-used-procedure .used-procedure-info-wrapper .used-procedure-info-note .quill .ql-container {
    border: 0;
}

.pdf-layout .update-used-procedure .used-procedure-info-wrapper.with-note .used-procedure-info-note.collapse:not(.show),
.pdf-layout .update-used-procedure .used-procedure-info-wrapper.with-description .used-procedure-info-description.collapse:not(.show),
.pdf-layout .update-used-procedure .used-procedure-info-wrapper.with-files .used-procedure-info-files.collapse:not(.show) {
    display: initial;
}
