@import url('_colors.css');

#landing-legal #row1 ul li {
    margin-bottom: .75em;
}

#landing-legal h1,
#landing-legal h2 {
    color: var(--darker-dodger-blue);
    font-size: 2.8rem;
    line-height: 1.3;
    font-weight: 600;
}

#landing-legal p,
#landing-legal ul,
#landing-legal ol {
    font-size: 1.8rem;
    line-height: 2.2rem;
}

#landing-legal .btn-lg {
    font-size: 1.5rem!important;
}

#landing-legal footer {
    color: var(--darker-dodger-blue);
    background-color: white;
}

#landing-legal .modal .modal-header {
    background-color: var(--dark-yellow);
}

#landing-legal .modal .modal-title {
    color: var(--darker-dodger-blue);
}

#landing-legal .responsive-video {
    width: 100%;
    height: auto;
}

#landing-legal .white-text {
    color: white;
}

#landing-legal .yellow-text {
    color: var(--dark-yellow);
}

#landing-legal .yellow-button {
    background-color: var(--dark-yellow);
    border-color: var(--dark-yellow);
    color: var(--darker-dodger-blue);
}

#landing-legal .yellow-button:hover,
#landing-legal .yellow-button:active {
    background-color: white;
    border-color: white;
}

#landing-legal .play-button {
    display: inline-block;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    padding: 40px 30px 40px 50px;
    background-color: var(--darker-dodger-blue);
}

#landing-legal .play-button:hover,
#landing-legal .play-button:active {
    background-color: var(--dodger-blue);
}

#landing-legal .jumbotron-fluid:not(#hero-banner):not(#row3):not(#row4):not(#row5) {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

#landing-legal .jumbotron-fluid h2+p,
#landing-legal .jumbotron-fluid h2+ul {
    margin-top: 3.25rem;
}

#landing-legal .jumbotron-fluid .btn:not(:first-child) {
    margin-top: 1rem;
}


#landing-legal .jumbotron-fluid#hero-banner {
    background: var(--lista-main-yellow) url('../img/lista-hero-bulb.png') no-repeat 20% center;
    background-position: calc(25% - 140px) center;
}

#landing-legal .jumbotron-fluid#row5 {
    background: var(--lista-main-yellow) url('../img/lista-hero-bulb.png') no-repeat 80% center;
    background-position: calc(75% + 140px) center;
}

#landing-legal .jumbotron-fluid#row5 .col-md-5,
#landing-legal .jumbotron-fluid#hero-banner .col-md-5,
#landing-legal .jumbotron-fluid#row3 .col-sm-5,
#landing-legal .jumbotron-fluid#row4 .col-sm-5 {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

#landing-legal #hero-banner .col-md-5:last-child::after {
    content: " ";
    position: absolute;
    display: block;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: var(--darker-dodger-blue);
    transform-origin: bottom left;
    transform: skewX(10deg);
}

#landing-legal #row5 .col-md-5:first-child::after {
    content: " ";
    position: absolute;
    display: block;
    width: 100vw;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 100;
    background: var(--darker-dodger-blue);
    transform-origin: top right;
    transform: skewX(10deg);
}

#landing-legal #row5 .col-md-5:first-child *,
#landing-legal #hero-banner .col-md-5:last-child *,
#landing-legal #row3 .col-sm-5:first-child *,
#landing-legal #row4 .col-sm-5:first-child * {
    z-index: 200;
    position: relative;
}

#landing-legal .jumbotron-fluid#row5 h1,
#landing-legal .jumbotron-fluid#hero-banner h1 {
    font-size: 3.75rem;
    font-weight: bold;
}

#landing-legal .jumbotron-fluid#row1 {
    background: linear-gradient(80deg, white, white 49.98%, var(--dark-yellow) 50.02%);
}

#landing-legal .jumbotron-fluid#row2 {
    background-color: var(--darker-dodger-blue);
}

#landing-legal .lista-definition {
    margin-top: 3.25rem;
}

#landing-legal .lista-definition p,
#landing-legal .lista-definition ol {
    font-size: 1.75rem;
    line-height: 2rem;
}

#landing-legal .jumbotron-fluid#row3 {
    background: white url('../img/lista-screenshot-procedure-create.png') no-repeat right bottom;
}

#landing-legal .jumbotron-fluid#row3 .col-sm-5:first-child::after {
    content: " ";
    position: absolute;
    display: block;
    width: 100vw;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 100;
    background: var(--dark-yellow);
    transform-origin: top right;
    transform: skewX(10deg);
}

#landing-legal .jumbotron-fluid#row4 {
    background: white url('../img/lista-screenshot-procedure-use.png') no-repeat left bottom;
}

#landing-legal .jumbotron-fluid#row4 .col-sm-5:first-child::after {
    content: " ";
    position: absolute;
    display: block;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: var(--lista-main-yellow);
    transform-origin: top left;
    transform: skewX(-10deg);
}

/*#landing-legal .jumbotron-fluid#row4 {*/
    /*background: linear-gradient(100deg, white, white 49.98%, var(--lista-main-yellow) 50.02%);*/
/*}*/

/*#landing-legal .jumbotron-fluid#row5 {*/
/*    background-color: white;*/
/*}*/

#landing-legal .jumbotron-fluid#row6 {
    background: linear-gradient(102deg, var(--darker-dodger-blue), var(--darker-dodger-blue) 49.98%, var(--dodger-blue) 50.02%);
}

#landing-legal .procedure-share {
    width: 100%;
    height: auto;
}

#landing-legal #row6 > .container > .row > div:first-child {
    margin-left: -50px;
}

#landing-legal #row6 > .container > .row > div:last-child {
    margin-right: -50px;
}


#landing-legal .repeatable-processes-wrapper {
    padding-right: 0;
}

#landing-legal #repeatable-processes {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--darker-dodger-blue);
    border: 2px solid var(--dark-yellow);
    border-radius: 12px;
    padding: 0 2% 4%;
    list-style-type: none;
    justify-content: space-between;
}

#landing-legal #repeatable-processes lh {
    flex: 0 1 100%;
    margin-bottom: 4%;
    padding: 1% 3%;
    vertical-align: top;
    background-color: var(--dark-yellow);
    border-radius: 0 0 8px 8px;
    color: var(--darker-dodger-blue);
    font-weight: bold;
    font-size: 1.75rem;
}

#landing-legal #repeatable-processes li {
    flex: 1 0 20%;
    background-color: var(--dodger-blue);
    border-radius: 8px;
    padding: 2%;
    font-size: 1.35rem;
    line-height: 1.5rem;
    color: var(--dark-yellow);
    box-shadow: 2px 2px 0px rgba(0,0,0,.2);
    max-width: 120px;
    min-width: 120px;
    min-height: 140px;
    margin: 0 5px 5px 5px;
}

#landing-legal #repeatable-processes li span {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

@media screen and (max-width: 1200px) {
    #landing-legal #row6 > .container > .row > div:first-child {
        margin:0;
        max-width: 40%;
    }

    #landing-legal #row6 > .container > .row > div:last-child {
        margin:0;
        max-width: 40%;
    }

    #landing-legal #repeatable-processes {
        justify-content: center;
    }
}

@media screen and (max-width: 991px) {

    #landing-legal .navbar-nav .btn {
        margin-left: .5rem;
        margin-right: 0 !important;
        padding-top: .2rem;
        padding-bottom: .2rem;
    }

    #landing-legal .navbar-nav .btn:first-child {
        margin-bottom: .25rem;
    }

    #landing-legal .procedure-share {
        /*height: 80%;*/
        /*width: auto;*/
        margin-top: 7rem;
    }

    #landing-legal #repeatable-processes {
        margin-bottom: 3.25rem;
    }


    #landing-legal #row6 > .container > .row > div:first-child {
        max-width: 100%;
    }

    #landing-legal #row6 > .container > .row > div:last-child {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    #landing-legal {
        overflow-x: hidden;
    }

    #landing-legal .jumbotron-fluid#hero-banner {
        background-position: center top;
    }

    #landing-legal .jumbotron-fluid#row5 {
        background-position: center bottom;
    }

    #landing-legal .jumbotron-fluid#row5 .col-md-5:first-child::after,
    #landing-legal .jumbotron-fluid#hero-banner .col-md-5:last-child::after {
        transform: none;
        width: 200vw;
        left: -200px;
    }

    #landing-legal .jumbotron-fluid#row1 {
        background: var(--dark-yellow);
    }

    #landing-legal .jumbotron-fluid#row6 {
        background: var(--darker-dodger-blue);
    }

    #landing-legal #hero-banner .col-md-5:last-child::after {
        background-color: white;
    }

    #landing-legal #hero-banner .col-md-5:last-child h2 {
        color: var(--darker-dodger-blue);
    }

    #landing-legal .jumbotron-fluid#row3 .col-sm-5:first-child::after {
        transform: skewX(0deg);
    }

}
