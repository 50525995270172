footer {
    /*padding: 31px 0; !* matching height of navbar *!*/
    padding: 20px 0; /* matching height of navbar */
    color:white;
    margin-top: auto;
    text-align: center;
}

footer ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin: 0;
}

footer ul li {
    margin: 0 5px;
}

footer ul li a {
    color: #ffffff;
}