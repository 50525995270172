:root {
    --darker-dodger-blue: #0069CF;
    --lista-main-yellow: #faeb8f;
    --dark-yellow: #ffd447;
    --lime-green: #2EC44A;
    --ocean-blue: #27C5E9;
    --main-orange: #f78534;
    /* Reconcile with marketing site */
    --dodger-blue: #1E90FF;
    --cta-red: #f22b60; /* Radical Red */
    --darker-cta-red: #b93659;
    --lista-marketing-yellow: #fdc005; /* Amber */
    --lista-pale-yellow: #fee493;
    /* --tag-color: #9f1ffe; Purple (X11) */
    --tag-color: #964ccd; /* Adjusted Purple */
    --lista-marketing-green: #0bbf97; /* Caribbean Green */
    --lista-marketing-green2: #079273; /* Adjusted Green for text */
    --border-gray: #eeeff2;
    --lighter-dodger-blue: #2CAFFF;
    --lista-lavender: #E9D7FB;
    --lista-lighter-lavender: #F3E8FF;
    --light-fill-gray: #F6F7FA;
    --lista-dark-gray: #272C30;
}
