.pricingContainer .pricing span {
    display: block;
    font-size: 12rem;
    font-weight: bold;
    line-height: 1;
    color: #0bbf97;
}
.pricingContainer .pricing {
    text-align: center;
}

.pricingContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

@media screen and (max-width:1050px) {
    .pricingContainer .pricing span {
        font-size: 10rem;
    }
}

@media screen and (max-width:450px) {
    .pricingContainer .pricing span {
        font-size: 6rem;
    }
}

@media screen and (max-width:250px) {
    .pricingContainer .pricing span {
        font-size: 2rem;
    }
}
